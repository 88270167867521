import CtaButtons from './CtaButtons'
import Footer from './Footer'
import FormField from './FormField'
import Header from './Header'
import Icon from './Icon'
import SectionContent from './SectionContent'
import SectionForm from './SectionForm'
import SectionHero from './SectionHero'
import SectionPosts from './SectionPosts'
import Layout from './Layout'

export {
	CtaButtons,
	Footer,
	FormField,
	Header,
	Icon,
	SectionContent,
	SectionForm,
	SectionHero,
	SectionPosts,
	Layout,
}

export default {
	CtaButtons,
	Footer,
	FormField,
	Header,
	Icon,
	SectionContent,
	SectionForm,
	SectionHero,
	SectionPosts,
	Layout,
}
